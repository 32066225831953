<template>
  <v-row no-gutters>
    <v-col>
      <div id="admin-list">
        <!-- app drawer -->

        <sms-add-new
          v-model="isAddNewSmsSidebarActive"
          :user-item="userItem"
          :user-type="userType"
          @refetch-data="fetchData"
        />

        <!-- list filters -->

        <v-card
          tile
          :flat="$vuetify.breakpoint.mdAndDown"
          :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg'"
        >
          <v-toolbar
            v-if="!$vuetify.breakpoint.smAndDown"
            :color="$vuetify.breakpoint.mdAndDown ? (isDark ? '#3b355a' : 'transparent') : 'transparent'"
            flat
            :height="$vuetify.breakpoint.smAndDown ? 57 : 87"
          >
            <v-toolbar-title class="text-h6 font-weight-medium">
              <v-icon large left>
                {{ icons.mdiMessageProcessing }}
              </v-icon>
              Toplu SMS
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn
              class="text-capitalize ms-3 rounded-l-pill rounded-r-0 mr-n4 bg-gradient-primary white--text"
              depressed
              x-large
              @click="userAdd()"
            >
              <span>Yeni SMS Gönder</span>
            </v-btn>
          </v-toolbar>

          <!-- table -->
          <v-data-table
            :headers="tableHeaders"
            :items="tableItems"
            :items-per-page="15"
            fixed-header
            :height="dataTableHeight"
            :loading="loading"
          >
            <template #[`item.createdatetime`]="{ item }">
              {{ item.createdatetime | moment('Do MMMM YYYY dddd') }}
            </template>
          </v-data-table>
        </v-card>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import { avatarText } from '@core/utils/filter'
import { mdiMessageProcessing } from '@mdi/js'
import { postData } from '@utils'
import { onMounted, ref } from '@vue/composition-api'
import SmsAddNew from './SmsAddNew.vue'

export default {
  components: {
    SmsAddNew,
  },
  setup() {
    const { isDark } = useAppConfig()
    const tableHeaders = ref([
      {
        text: 'ID',
        value: 'id',
        width: '100px',
      },

      {
        text: 'Başlık',
        value: 'subject',
        width: '250px',
      },
      {
        text: 'İçerik',
        value: 'body',
        align: 'left min-width-400',
      },
      {
        text: 'Tarih',
        value: 'createdatetime',
        width: '250px',
      },
    ])
    const tableItems = ref([])
    const loading = ref(true)
    const isAddNewSmsSidebarActive = ref(false)
    const userType = ref('add')
    const userItem = ref({})
    const fetchData = () => {
      postData({
        method: 'smsPostList',
      })
        .then(data => {
          if (data.error == 0) {
            tableItems.value = data.detail
          }
        })
        .finally(() => {
          loading.value = false
        })
    }
    onMounted(() => {
      fetchData()
    })

    const userAdd = () => {
      userItem.value = {}
      userType.value = 'add'
      isAddNewSmsSidebarActive.value = true
    }

    return {
      fetchData,
      userAdd,
      isDark,
      isAddNewSmsSidebarActive,
      avatarText,
      tableHeaders,
      tableItems,
      loading,
      userItem,
      userType,

      // icons
      icons: {
        mdiMessageProcessing,
      },
    }
  },
  data() {
    return {
      panel: null,
      date: null,
      modal: false,
      filterStatus: false,
      dataTableHeight: '',
      height: {
        system: 0,
        top: this.$vuetify.breakpoint.smAndDown ? 48 : 87,
        footer: this.$vuetify.breakpoint.smAndDown ? 47 : 47,
      },
    }
  },

  watch: {
    panel(val) {
      if (val === 0) {
        setTimeout(() => {
          this.height.top = 250
          this.onResize()
        }, 300)
      } else {
        this.height.top = 48
        this.onResize()
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.onResize()
      window.addEventListener('resize', this.onResize)
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.dataTableHeight = window.innerHeight - 90 - this.height.top - this.height.footer - 1
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
